import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-661b5aca")
const _hoisted_1 = { class: "operation-item" }
const _hoisted_2 = { class: "operation-content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "operation-info" }
const _hoisted_5 = { class: "operation-title" }
const _hoisted_6 = ["innerHTML"]
_popScopeId()

import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { OpenStatus, RouterName } from '@/enum'
import { setApptitle, isInAppWebview } from '@/utils/native-app'
import useOpenInfo from '@/hooks/openInfo'
import * as storage from '@/utils/storage'
import redirectUrl from '@/utils/redirect-url'
import { queryFn } from '@/graphql/index'
import utc from 'dayjs/plugin/utc'
import apolloClient from '@/apollo-client'
import { queryApi } from '@/graphql/query'
import * as types from '@/store/modules/common/mutation-type'


export default _defineComponent({
  props: {
  text: {
    type: String,
    default: '开通普通账户'
  },
  icon: {
    type: String,
    default: '投资移民开户'
  },
  type: {
    type: String,
    default: 'openAccount'
  },
  openCommon: {
    type: String,
    default: ''
  }
},
  emits: ['queryBgImgUrl'],
  setup(__props, { emit: emits }) {

const props = __props
dayjs.extend(utc)





const { title } = useOpenInfo()
setApptitle(title)
const isApp = isInAppWebview()
const store = useStore()
const router = useRouter()
const btnText = ref()
const tips = ref()
const imgUrl = ref()
const result = ref()

const openCommon = computed(() => JSON.parse(props.openCommon || '{}'))

const iconSrc = computed(() => require(`@/assets/images/icons/${props.icon}.svg`))

const name = ref('')
const applicationStatus = ref(0)

const goPage = async (name: string) => {
  if (name) {
    const queryFunMap = {
      openAccount: queryApi.clientFormDraftAll,
      openImmigrantAccount: queryApi.clientFormDraftAllByOpenImmigrantAccount
    } as any
    const formType = props.type as string
    const res = await apolloClient.query({
      query: queryFunMap[formType] || queryFunMap.openAccount,
      fetchPolicy: 'no-cache',
      variables: {
        kindSource: 1
      }
    })
    result.value = res?.data
    let landInfo = {}
    let othersInfo = {}
    let common = {}
    Object.keys(result.value).forEach(item => {
      if (result.value[item].data.meta.startsWith('land')) {
        const content = JSON.parse(result.value[item].data.content)
        landInfo = { ...landInfo, ...content }
      } else if (result.value[item].data.meta.startsWith('others')) {
        const content = JSON.parse(result.value[item].data.content)
        othersInfo = { ...othersInfo, ...content }
      } else {
        const content = JSON.parse(result.value[item].data.content)
        common = content
      }
    })
    store.commit(types.OPEN_INFO_LAND, { info: landInfo })
    store.commit(types.OPEN_INFO_OTHERS, { info: othersInfo })
    store.commit(types.OPEN_COMMON, { info: common })
    console.log(name, RouterName.TRANSFER_STEP, applicationStatus.value)
    if (name === RouterName.TRANSFER_STEP && applicationStatus.value === 13) {
      router.push({ name, query: { type: props.type, hide: 'hide' } })
    } else {
      router.push({ name, query: { type: props.type } })
    }
    storage.set('formType', props.type)
  }
}

const toLogin = () => {
  if (isApp) return
  redirectUrl()
  storage.clear()
}

const stepList: any = {
  1: RouterName.ID_CARD,
  2: RouterName.ID_CARD_INFO,
  3: RouterName.CONTACT_INFO,
  4: RouterName.FINANCIAL_INFO,
  5: RouterName.TAX_INFO,
  6: RouterName.RISK,
  7: RouterName.OTHER,
  8: RouterName.PI,
  9: RouterName.PI_RISK,
  10: RouterName.PI_AR,
  11: RouterName.STATEMENT,
  12: RouterName.CAPTCHA,
  13: RouterName.CONFIRM,
  14: RouterName.TRANSFER_STEP,
  15: RouterName.BIND_CARD,
  16: RouterName.TRANSFER,
  17: RouterName.TRANSFER_PRO,
  18: RouterName.ACCOUNT_TYPE
}

const statusNoSubmit = () => {
  console.log(openCommon.value)
  const routerName = stepList[openCommon.value.step]
    ? stepList[openCommon.value.step]
    : RouterName.ID_TYPE
  if (openCommon.value.openStatus === 0) {
    btnText.value = '继续开户'
    name.value = routerName
    tips.value = openCommon.value.remark
    imgUrl.value = require('@/assets/images/continue@2x.png')
  } else if (openCommon.value.openStatus === 1) {
    btnText.value = '身份核实'
    name.value = routerName
    tips.value = openCommon.value.remark
    imgUrl.value = require('@/assets/images/continue@2x.png')
  } else {
    btnText.value = '立即开户'
    imgUrl.value = require('@/assets/images/quick@2x.png')
    name.value = routerName
  }
}

const openAccountTypeMap:any = {
  openAccount: 1, // 普通开户
  openImmigrantAccount: 9 // 移民开户
}

onMounted(async () => {
  const res = await queryFn('customerOpenStatus', {
    openAccountType: openAccountTypeMap[props.type] || 1
  })
  const { data, ok } = res
  if (ok) {
    switch (data.applicationStatus) {
      case OpenStatus.REVIEW:
        btnText.value = '审核中'
        tips.value = '预计在3个工作日内完成'
        setApptitle('提交成功')
        imgUrl.value = require('@/assets/images/review@2x.png')
        break
      case OpenStatus.DONE:
        applicationStatus.value = 2
        // imgUrl.value = require('@/assets/images/done.png')
        imgUrl.value = require('@/assets/images/review@2x.png')
        break
      case OpenStatus.FAIL: // 开户失败
        btnText.value = '开户失败'
        tips.value = '开户审核不通过，请<a>查看驳回原因</a>'
        imgUrl.value = require('@/assets/images/reviewfail@2x.png')
        break
      case OpenStatus.OPEN_REJECT: // 审核驳回
        btnText.value = '重新填写资料'
        name.value = RouterName.ID_TYPE
        tips.value = `开户审核不通过，请<a href="/open-account/reason?type=${props.type}">查看驳回原因</a>`
        imgUrl.value = require('@/assets/images/reviewfail@2x.png')
        break
      case OpenStatus.FAIL_BLACK: // 审核驳回并拉入黑名单
        btnText.value = '开户失败'
        tips.value = `开户失败，请<a href="/open-account/reason?type=${props.type}">查看驳回原因</a>`
        imgUrl.value = require('@/assets/images/openfail@2x.png')
        break
      case OpenStatus.SUPPLEMENT:
        btnText.value = '上传转账凭证'
        tips.value = '已完成90%，请继续完善资料'
        name.value = RouterName.TRANSFER_STEP
        applicationStatus.value = OpenStatus.SUPPLEMENT
        imgUrl.value = require('@/assets/images/continue@2x.png')
        break
      default:
        statusNoSubmit()
        break
    }
    emits('queryBgImgUrl', imgUrl.value)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _unref(iconSrc) }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(__props.text), 1),
        (btnText.value !== '立即开户')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "operation-tip",
              innerHTML: tips.value
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ]),
    (applicationStatus.value === 2)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "operation-btn",
          onClick: toLogin
        }, "完成开户"))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "operation-btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (goPage(name.value)))
        }, _toDisplayString(btnText.value), 1))
  ]))
}
}

})